<template>
  <PDialog 
    :title="`Epost`" 
    classes="left-0 md:left-auto md:w-4/5 lg:w-3/5"
    :loading="isLoading"
    @close="$emit('close')"
  >
    <PDialogContent v-if="mailArchive">

      <div class="p-4 text-sm bg-green-100 rounded-lg">
        
        <div class="flex items-center space-x-4">
          <div class="w-32 font-semibold" v-html="$tk('MailArchiveAttachment.Sent')"></div>
          <div>{{ mailArchive.sentLocal | dateAndTime }}</div>
        </div>

        <div class="flex items-center space-x-4">
          <div class="w-32 font-semibold" v-html="$tk('MailArchiveAttachment.To')"></div>
          <div>
            <span v-for="(email, index) in emails" :key="index">
              <a 
                class="text-green-700 underline" 
                :href="`mailto:${email}`"
              >{{ email }}</a>{{ (index !== emails.length - 1) ? ", " : "" }}
            </span>   
          </div>
        </div>

        <div class="flex items-center space-x-4">
          <div class="w-32 font-semibold" v-html="$tk('MailArchiveAttachment.Topic')"></div>
          <div>{{ mailArchive.subject }}</div>
        </div>
      
      </div>

      <div class="mt-8 text-sm" v-html="mailArchive.body"></div>

      <MailArchiveAttachment :mailArchive="mailArchive" :attachmentIndex="1" />
      <MailArchiveAttachment :mailArchive="mailArchive" :attachmentIndex="2" />

    </PDialogContent>
    <PDialogActions>

      <PButton 
        @click="$emit('close')" 
        color="secondary"        
      >
        <span v-html="$tk('Common.Actions.Close')"></span>
      </PButton>
      
    </PDialogActions>
  </PDialog>
  
</template>

<script>

import http from "@/http"
import { get } from "lodash"
import MailArchiveAttachment from "./MailArchiveAttachment.vue"

export default {
  
  components: {
    MailArchiveAttachment
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      mailArchive: null,
      isLoading: false
    }
  },

  computed: {

    emails () {
      return get(this.mailArchive, "email", "").split(";")      
    }

  },


  async created () {  

    this.isLoading = true    

    try {

      this.mailArchive = await http.get("mailarchive", {
        params: {
          id: this.id,
          withAttachments: true
        }
      })

    } catch (error) {

      this.$store.dispatch("notify", { 
        type: "negative", 
        text: error.reason 
      })

    }

    this.isLoading = false
  }
}
</script>
