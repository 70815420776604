<template>
  <div v-if="attachment">

    <template v-if="attachment.type === 'application/pdf'">
      <a 
        class="inline-flex items-center mt-8 text-sm underline"
        :download="attachment.name" 
        :href="`data:application/pdf;base64,${attachment.data}`" 
      >
        <FontAwesomeIcon :icon="['far', 'file-pdf']" />

        <span class="ml-2">
            {{ attachment.name }}
        </span>
        
      </a>
    </template>

    <template v-else-if="attachment.type.indexOf('image') === 0">
      <p-thumbnail-image
        class="w-56 mt-4"
        :data="attachment.data"
        :name="attachment.name"
      />  
    </template>

  </div>
</template>

<script>

export default {

  name: "mail-archive-attachment",

  props: {
    mailArchive: {
      type: Object,
      required: true
    },
    attachmentIndex: {
      type: Number,
      required: true
    }
  },
  
  computed: {

    attachment () {
      
      const data = this.mailArchive[`attachment${this.attachmentIndex}Data`]
      const name = this.mailArchive[`attachment${this.attachmentIndex}Name`]
      const type = this.mailArchive[`attachment${this.attachmentIndex}Type`]

      if (data && name && type) {
        return {
          data,
          name,
          type
        }
      }

      return null
    }

  }
}
</script>