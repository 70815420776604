<template>
  <div class="flex flex-col flex-1 overflow-hidden bg-white border border-gray-300 rounded">
    <div class="relative flex-1 overflow-y-scroll">
      <p-loader v-if="isLoading" />
      <table v-else class="data-table">
        <thead>
          <tr>
            <th class="text-left" v-html="$tk('EmailArchive.Sent')"></th>
            <th class="text-left" v-html="$tk('EmailArchive.ToCustomer')"></th>
            <th class="text-left" v-html="$tk('EmailArchive.FromLocation')"></th>
            <th class="text-left" v-html="$tk('EmailArchive.ToUser')"></th>
            <th class="text-left" v-html="$tk('EmailArchive.Topic')"></th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="(mailArchive, index) in mailarchives" 
            :key="index" 
            :class="{'odd': index % 2 !== 0 }"
            @click="selectedId = mailArchive.id"
          >
            <td>
              {{ mailArchive.sentLocal | dateAndTime }}
            </td>
            <td>
              {{ mailArchive.customerName }}
            </td>
            <td>
              {{ mailArchive.locationName }}
            </td>
            <td>
              {{ mailArchive.email }}
            </td>
            <td>
              {{ mailArchive.subject }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <portal to="filters">
      <div class="flex space-x-2">
        
        <PDatePicker 
          class="w-36" 
          :placeholder="$tk('EmailArchive.FromDate')" 
          v-model="sentLower" 
          :clearable="true" 
          :rightAlign="true" 
        />

        <PDatePicker 
          class="w-36" 
          :placeholder="$tk('EmailArchive.ToDate')" 
          v-model="sentUpper" 
          :clearable="true" 
          :rightAlign="true" 
        />

      </div>
    </portal>


    <mail-dialog 
      v-if="selectedId"
      :id="selectedId"
      @close="selectedId = ''" 
    />
    

  </div>
</template>

<script>

import http from "@/http"
import MailDialog from "./dialogs/MailDialog.vue"
import { mapGetters } from "vuex"

export default {

  components: {
    MailDialog
  },

  data () {
    return {
      sentLower: "",
      sentUpper: "",
      onlyForMe: false,
      mailarchives: [],
      selectedId: "",
      isLoading: false
    }
  },

  computed: {

    ...mapGetters(["user"]),

    email () {
      return this.onlyForMe ? this.user.email : ""
    }

  },


  methods: {

    async load () {
      this.isLoading = true

      let params = {}

      if (this.sentLower) params.sentLower = this.sentLower
      if (this.sentUpper) params.sentUpper = this.sentUpper
      if (this.email) params.email = this.email

      this.mailarchives = await http.get("mailarchives", { params })
      this.isLoading = false
    }

  },


  async created () {
    await this.load()
  },


  watch: {
    // location: async function () {
    //   await this.load()
    // },
    sentLower: async function () {
      await this.load()
    },
    sentUpper: async function () {
      await this.load()
    },
    onlyForMe: async function () {
      await this.load()
    }
  }


}
</script>

<style scoped>

th {
  @apply p-4 leading-4 bg-gradient-to-b from-gray-500 to-gray-600;
}

tr {
  background-color: theme('colors.gray.50');
}

tr.odd {
  background-color: theme('colors.gray.100')
}

tr:hover {
  background-color: theme('colors.orange.200');
}

td {
  @apply px-4 py-2;
  border-bottom: 1px solid theme('colors.gray.300');
  border-right: 1px solid theme('colors.gray.300');
}

tbody tr {
  cursor: pointer;
}

</style>